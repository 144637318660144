const moment = require('moment-timezone');

const daysLeft = (date) => {
  const now = moment(new Date());
  const nowSydney = now.tz('Australia/Sydney');
  const end = moment(date);
  const duration = moment.duration(end.diff(nowSydney));
  return duration.asDays();
};

export default daysLeft;
