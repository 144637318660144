import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import DaysLeft from './daysLeft';
import '../assets/styles/components/artists.scss';

const Artists = () => {
  const artist = useStaticQuery(graphql`
    query {
      allArtist {
        edges {
          node {
            name,
            slug,
            excerpt,
            releaseDate,
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const listenNow = (node) => {
    let classes = 'listen-now-wrapper coming-soon';
    let text = 'Listen soon';
    const days = DaysLeft(node.releaseDate);

    if (days <= 0) {
      classes = 'listen-now-wrapper';
      text = 'Live now';
    }

    return <Link to={`/artist/${node.slug}`} className={classes}>{text}</Link>;
  };

  return (
    <div id="section-artists">
      <h2 className="section-artists-title">The artists</h2>
      <div className="artists-wrapper">
        {artist.allArtist.edges.map(({ node }) => (
          <div key={node.slug} className="artist-block">
            <div className="artist-inner-wrapper">
              {listenNow(node)}
              <Link
                className="artist-block-inner"
                to={`/artist/${node.slug}`}
              >
                <Img fluid={node.image.childImageSharp.fluid} />
                <div className="hidden-text">
                  <h3>
                    {node.name}
                  </h3>
                </div>
                <span className="artist-block-hover">
                  <h3>
                    {node.name}
                  </h3>
                  <p
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: node.excerpt,
                    }}
                  />
                </span>
              </Link>
              <Link
                className="arrow-block"
                to={`/artist/${node.slug}`}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Artists;
